module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MHX9W5W","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"333376324481874"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Noora","short_name":"Noora","start_url":"/","background_color":"#FF0476","theme_color":"#FF0476","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9c68e49f4cd4913fdf6969e468f3ba25"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en"],"defaultLanguage":"en","siteUrl":"https://noorahq.com","pages":[{"matchPath":"/:lang?/blog/:uid","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/changelog/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/cookiepolicy/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/privacypolicy/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/slack/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/help-documentation/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/track-feature-requests/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/support-tickets/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/public-roadmap/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/termsofservice/","excludeLanguages":["sv","pt-BR"]},{"matchPath":"/:lang?/compare/:uid","excludeLanguages":["sv","pt-BR"]}],"i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"returnEmptyString":false}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
