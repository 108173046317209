exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-3-best-ways-to-collect-game-feedback-tsx": () => import("./../../../src/pages/blog/3-best-ways-to-collect-game-feedback.tsx" /* webpackChunkName: "component---src-pages-blog-3-best-ways-to-collect-game-feedback-tsx" */),
  "component---src-pages-blog-best-changelog-tools-tsx": () => import("./../../../src/pages/blog/best-changelog-tools.tsx" /* webpackChunkName: "component---src-pages-blog-best-changelog-tools-tsx" */),
  "component---src-pages-blog-best-feature-voting-tools-tsx": () => import("./../../../src/pages/blog/best-feature-voting-tools.tsx" /* webpackChunkName: "component---src-pages-blog-best-feature-voting-tools-tsx" */),
  "component---src-pages-blog-best-practices-with-collecting-feedback-and-feature-requests-in-intercom-tsx": () => import("./../../../src/pages/blog/best-practices-with-collecting-feedback-and-feature-requests-in-intercom.tsx" /* webpackChunkName: "component---src-pages-blog-best-practices-with-collecting-feedback-and-feature-requests-in-intercom-tsx" */),
  "component---src-pages-blog-how-to-choose-the-right-public-roadmap-tool-for-your-product-roadmap-tsx": () => import("./../../../src/pages/blog/how-to-choose-the-right-public-roadmap-tool-for-your-product-roadmap.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-choose-the-right-public-roadmap-tool-for-your-product-roadmap-tsx" */),
  "component---src-pages-blog-how-to-collect-and-manage-customer-feedback-to-improve-your-saas-product-tsx": () => import("./../../../src/pages/blog/how-to-collect-and-manage-customer-feedback-to-improve-your-saas-product.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-collect-and-manage-customer-feedback-to-improve-your-saas-product-tsx" */),
  "component---src-pages-blog-how-to-respond-to-feature-requests-in-intercom-tsx": () => import("./../../../src/pages/blog/how-to-respond-to-feature-requests-in-intercom.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-respond-to-feature-requests-in-intercom-tsx" */),
  "component---src-pages-blog-how-to-set-up-a-changelog-tool-for-your-product-or-website-tsx": () => import("./../../../src/pages/blog/how-to-set-up-a-changelog-tool-for-your-product-or-website.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-set-up-a-changelog-tool-for-your-product-or-website-tsx" */),
  "component---src-pages-blog-the-best-examples-of-open-startups-with-public-roadmaps-tsx": () => import("./../../../src/pages/blog/the-best-examples-of-open-startups-with-public-roadmaps.tsx" /* webpackChunkName: "component---src-pages-blog-the-best-examples-of-open-startups-with-public-roadmaps-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-case-studies-encharge-tsx": () => import("./../../../src/pages/case-studies/encharge.tsx" /* webpackChunkName: "component---src-pages-case-studies-encharge-tsx" */),
  "component---src-pages-case-studies-invisible-walls-first-class-trouble-tsx": () => import("./../../../src/pages/case-studies/invisible-walls-first-class-trouble.tsx" /* webpackChunkName: "component---src-pages-case-studies-invisible-walls-first-class-trouble-tsx" */),
  "component---src-pages-case-studies-the-story-graph-tsx": () => import("./../../../src/pages/case-studies/the-story-graph.tsx" /* webpackChunkName: "component---src-pages-case-studies-the-story-graph-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-compare-canny-alternative-tsx": () => import("./../../../src/pages/compare/canny-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-canny-alternative-tsx" */),
  "component---src-pages-compare-trello-roadmap-alternative-tsx": () => import("./../../../src/pages/compare/trello-roadmap-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-trello-roadmap-alternative-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-compare-upvoty-alternative-tsx": () => import("./../../../src/pages/compare/upvoty-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-upvoty-alternative-tsx" */),
  "component---src-pages-compare-uservoice-alternative-tsx": () => import("./../../../src/pages/compare/uservoice-alternative.tsx" /* webpackChunkName: "component---src-pages-compare-uservoice-alternative-tsx" */),
  "component---src-pages-cookiepolicy-tsx": () => import("./../../../src/pages/cookiepolicy.tsx" /* webpackChunkName: "component---src-pages-cookiepolicy-tsx" */),
  "component---src-pages-demo-embed-tsx": () => import("./../../../src/pages/demo-embed.tsx" /* webpackChunkName: "component---src-pages-demo-embed-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-tsx": () => import("./../../../src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */),
  "component---src-pages-intercom-tsx": () => import("./../../../src/pages/intercom.tsx" /* webpackChunkName: "component---src-pages-intercom-tsx" */),
  "component---src-pages-lp-canny-tsx": () => import("./../../../src/pages/lp/canny.tsx" /* webpackChunkName: "component---src-pages-lp-canny-tsx" */),
  "component---src-pages-lp-featureupvote-tsx": () => import("./../../../src/pages/lp/featureupvote.tsx" /* webpackChunkName: "component---src-pages-lp-featureupvote-tsx" */),
  "component---src-pages-lp-nolt-tsx": () => import("./../../../src/pages/lp/nolt.tsx" /* webpackChunkName: "component---src-pages-lp-nolt-tsx" */),
  "component---src-pages-lp-stop-guessing-tsx": () => import("./../../../src/pages/lp/stop-guessing.tsx" /* webpackChunkName: "component---src-pages-lp-stop-guessing-tsx" */),
  "component---src-pages-lp-upvoty-tsx": () => import("./../../../src/pages/lp/upvoty.tsx" /* webpackChunkName: "component---src-pages-lp-upvoty-tsx" */),
  "component---src-pages-lp-user-feedback-software-tsx": () => import("./../../../src/pages/lp/user-feedback-software.tsx" /* webpackChunkName: "component---src-pages-lp-user-feedback-software-tsx" */),
  "component---src-pages-lp-uservoice-tsx": () => import("./../../../src/pages/lp/uservoice.tsx" /* webpackChunkName: "component---src-pages-lp-uservoice-tsx" */),
  "component---src-pages-nps-tsx": () => import("./../../../src/pages/nps.tsx" /* webpackChunkName: "component---src-pages-nps-tsx" */),
  "component---src-pages-powered-by-noora-tsx": () => import("./../../../src/pages/powered-by-noora.tsx" /* webpackChunkName: "component---src-pages-powered-by-noora-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-product-market-fit-tsx": () => import("./../../../src/pages/product-market-fit.tsx" /* webpackChunkName: "component---src-pages-product-market-fit-tsx" */),
  "component---src-pages-product-roadmap-templates-now-next-later-product-roadmap-tsx": () => import("./../../../src/pages/product-roadmap-templates/now-next-later-product-roadmap.tsx" /* webpackChunkName: "component---src-pages-product-roadmap-templates-now-next-later-product-roadmap-tsx" */),
  "component---src-pages-product-roadmap-templates-quarterly-product-roadmap-tsx": () => import("./../../../src/pages/product-roadmap-templates/quarterly-product-roadmap.tsx" /* webpackChunkName: "component---src-pages-product-roadmap-templates-quarterly-product-roadmap-tsx" */),
  "component---src-pages-product-roadmap-templates-tsx": () => import("./../../../src/pages/product-roadmap-templates.tsx" /* webpackChunkName: "component---src-pages-product-roadmap-templates-tsx" */),
  "component---src-pages-public-roadmap-tsx": () => import("./../../../src/pages/public-roadmap.tsx" /* webpackChunkName: "component---src-pages-public-roadmap-tsx" */),
  "component---src-pages-security-tsx": () => import("./../../../src/pages/security.tsx" /* webpackChunkName: "component---src-pages-security-tsx" */),
  "component---src-pages-slack-tsx": () => import("./../../../src/pages/slack.tsx" /* webpackChunkName: "component---src-pages-slack-tsx" */),
  "component---src-pages-termsofservice-tsx": () => import("./../../../src/pages/termsofservice.tsx" /* webpackChunkName: "component---src-pages-termsofservice-tsx" */),
  "component---src-pages-tools-collect-game-feedback-tsx": () => import("./../../../src/pages/tools/collect-game-feedback.tsx" /* webpackChunkName: "component---src-pages-tools-collect-game-feedback-tsx" */),
  "component---src-pages-tools-feature-voting-tsx": () => import("./../../../src/pages/tools/feature-voting.tsx" /* webpackChunkName: "component---src-pages-tools-feature-voting-tsx" */),
  "component---src-pages-tools-feedback-boards-tsx": () => import("./../../../src/pages/tools/feedback-boards.tsx" /* webpackChunkName: "component---src-pages-tools-feedback-boards-tsx" */),
  "component---src-pages-track-feature-requests-tsx": () => import("./../../../src/pages/track-feature-requests.tsx" /* webpackChunkName: "component---src-pages-track-feature-requests-tsx" */),
  "component---src-pages-use-cases-tsx": () => import("./../../../src/pages/use-cases.tsx" /* webpackChunkName: "component---src-pages-use-cases-tsx" */),
  "component---src-pages-user-feedback-software-tsx": () => import("./../../../src/pages/user-feedback-software.tsx" /* webpackChunkName: "component---src-pages-user-feedback-software-tsx" */)
}

