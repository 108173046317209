function getCookie(name) {
	const cookieName = `${name}=`;
	const cookieParts = document.cookie.split(";");

	for (let iCookie = 0; iCookie < cookieParts.length; ++iCookie) {
		let cookie = cookieParts[iCookie];
		while (cookie.charAt(0) === " ")
			cookie = cookie.substring(1);

		if (cookie.indexOf(cookieName) === 0)
			return cookie.substring(cookieName.length, cookie.length);	
	}

	return null;
}

function setCookie(name, value) {
	const cookieValue = `${name}=${value};path=/`
	document.cookie = cookieValue;
}

function generateUUID() {
    // Time/ticks information
    // 1*new Date() is a cross browser version of Date.now()
    var T = function() {
        var d = 1 * new Date(),
            i = 0;

        // this while loop figures how many browser ticks go by
        // before 1*new Date() returns a new number, ie the amount
		// of ticks that go by per millisecond
        while (d === 1 * new Date()) {
            i++;
        }

        return d.toString(16) + i.toString(16);
    };

    // Math.Random entropy
    var R = function() {
        return Math.random().toString(16).replace('.', '');
    };

    // User agent entropy
    // This function takes the user agent string, and then xors
    // together each sequence of 8 bytes.  This produces a final
    // sequence of 8 bytes which it returns as hex.
    var UA = function() {
        var ua = window.navigator.userAgent,
            i, ch, buffer = [],
            ret = 0;

        function xor(result, byte_array) {
            var j, tmp = 0;
            for (j = 0; j < byte_array.length; j++) {
                tmp |= (buffer[j] << j * 8);
            }
            return result ^ tmp;
        }

        for (i = 0; i < ua.length; i++) {
			ch = ua.charCodeAt(i);
            buffer.unshift(ch & 0xFF);
            if (buffer.length >= 4) {
                ret = xor(ret, buffer);
                buffer = [];
            }
        }

        if (buffer.length > 0) {
            ret = xor(ret, buffer);
        }

        return ret.toString(16);
    };

	var se = (window.screen.height * window.screen.width).toString(16);
	return (T() + '-' + R() + '-' + UA() + '-' + se + '-' + T());
}

exports.onRouteUpdate = () => {
	const trackingIdKey = "_noora_anonymous_tracking_id";
	let anonymousTrackingId = getCookie(trackingIdKey);
	if (!anonymousTrackingId) {
        anonymousTrackingId = generateUUID();
        setCookie(trackingIdKey, anonymousTrackingId);
    }

	const xhr = new XMLHttpRequest();
	xhr.responseType = "json";
	xhr.open("POST", "https://api.noorahq.com/graphql", true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("X-Noora-Atid", anonymousTrackingId);
	xhr.onload = function () {};

	xhr.send(JSON.stringify({
		query: `mutation { trackEvents(input: { events: [{ event: "pageView" path: "" currentUrl: "${window.location.href}" referrer: "${document.referrer}" screenWidth: ${window.screen.width} screenHeight: ${window.screen.height} }] }) }`,
	}));
}